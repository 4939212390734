
import { DrawingRequestStatus, ProductSetDetails, RequestFor3DDrawingDetailDto } from '@/__generated__/globalTypes'
import RoomSelection from '@/components/roomSelection/RoomSelection.vue'
import { CombinationWithProductSets, Product } from '@/components/roomSelection/types'
import QUERIES from '@/queries/queries'
import { toLocaleDateTimeString } from '@/utils/dateUtils'
import { Component, Vue } from 'vue-property-decorator'
import {
  ProductPackageSetFragment,
  ProductSetDetailsDocument,
  ProductSetDetailsQuery,
  ProductSetDetailsQueryVariables
} from './gql/__generated__/getProductSetDetails.query'
import {
  RequestFor3DDrawingDetailsDocument,
  RequestFor3DDrawingDetailsQuery,
  RequestFor3DDrawingDetailsQueryVariables
} from './gql/__generated__/getRequestFor3dDrawingDetails.query'

@Component({
  components: {
    RoomSelection
  }
})
export default class DrawingRequestDetailsView extends Vue {
  drawingData: RequestFor3DDrawingDetailDto | null = null
  productSetCmsData: ProductSetDetails[] = []
  productSetsWithInfoKeys: ProductPackageSetFragment[] = []

  DAY_IN_MILLISECONDS = 86400000

  created() {
    this.$apollo.addSmartQuery<RequestFor3DDrawingDetailsQuery, RequestFor3DDrawingDetailsQueryVariables>(
      QUERIES.RequestFor3DDrawingDetails,
      {
        query: RequestFor3DDrawingDetailsDocument,
        variables: () => ({ id: this.$route.params?.id }),
        update: (data) => data,
        result: (result) => {
          this.drawingData = (result.data.requestFor3DDrawingDetails as RequestFor3DDrawingDetailDto) ?? null
        },
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4178',
            Message: error
          })
        }
      }
    )

    this.$apollo.addSmartQuery<ProductSetDetailsQuery, ProductSetDetailsQueryVariables>(QUERIES.ProductSetDetails, {
      query: ProductSetDetailsDocument,
      variables: () => ({
        productSetIds: this.productSetIds,
        stringyfiedProductSetIds: this.productSetIds,
        productPackageId: this.drawingData?.requestFor3DDrawing?.designPackage?.productPackage?.id ?? '',
        currentLang: this.currentLang
      }),
      update: (data) => data,
      result: (result) => {
        this.productSetCmsData = (result.data.productSetDetails ?? []) as ProductSetDetails[]
        this.productSetsWithInfoKeys = result.data.productPackage?.assignedProductSets ?? []
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4179',
          Message: error
        })
      },
      skip: () => !this.drawingData
    })
  }

  get productSetIds() {
    return (
      this.drawingData?.productPackageSets.map((productPackageSets) => productPackageSets.value.productSet?.id) ?? []
    )
  }

  get currentLang() {
    return this.$i18n.locale
  }

  get combinationsWithSelectedProductSets(): CombinationWithProductSets[] {
    // Group product sets by combination
    const combinations =
      this.drawingData?.productPackageSets?.reduce((acc: CombinationWithProductSets[], productPackageSet) => {
        const productSetcombination = productPackageSet?.value.productCombination
        const combinationInList: CombinationWithProductSets | null =
          acc?.find((c: CombinationWithProductSets) => c.id === productSetcombination.id) ?? null
        const cmsItem = this.productSetCmsData.find((ps) => ps.alias?.alias === productPackageSet?.value.productSet?.id)
        const productSet = {
          name: productPackageSet?.value.productSet.name,
          id: productPackageSet?.value.productSet.id,
          assignedProductSetProducts: productPackageSet?.value.productSet.assignedProductSetProducts.map(
            (x) => x.product as Product
          ),
          position: productPackageSet?.value.productPackageCategory?.productCategory?.position ?? 0,
          imagePath: cmsItem?.thumbnail?.urls?.[0] ?? cmsItem?.images?.urls?.[0] ?? '',
          description: cmsItem?.shortDescriptionHTML?.html ?? '',
          productSetInfos:
            this.productSetsWithInfoKeys
              ?.find((x) => x.productSet.id === productPackageSet?.value.productSet.id)
              ?.displayableProductSetInfos?.map((y) => ({
                label: y?.label ?? '',
                value: y?.localizedValue?.join(', ') ?? ''
              })) ?? []
        }
        // If the combination doesn't exist in the list yet, create a new one with this product set,
        // otherwise just add the product set to the existing combination
        if (!combinationInList) {
          acc.push({
            id: productSetcombination.id,
            combinationType: productSetcombination.combinationType,
            name: productSetcombination.name,
            position: productSetcombination.position,
            productSets: [productSet]
          })
        } else {
          combinationInList.productSets.push(productSet)
        }

        // Sort the product sets based on the position of their category (is already mapped)
        acc.forEach((x) => x.productSets.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0)))

        return acc
      }, []) ?? []

    return combinations.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))
  }

  get designName() {
    return this.drawingData?.requestFor3DDrawing?.selectionName
  }

  get roomName() {
    return this.drawingData?.requestFor3DDrawing?.designPackage?.room?.name
  }

  get buildNumber() {
    return this.drawingData?.requestFor3DDrawing?.realEstate?.buildNumber
  }

  get projectName() {
    return this.drawingData?.requestFor3DDrawing?.realEstate?.project?.name
  }

  get status() {
    return this.drawingData?.requestFor3DDrawing.request3DDrawingStatus
  }

  get drawingStatus() {
    const statusKey =
      this.status === DrawingRequestStatus.Requested && this.isRequestCancellable()
        ? 'REQUESTED_AND_CANCELABLE'
        : this.status
    return this.$t(`drawings.status.${statusKey}`)
  }

  get statusColor() {
    switch (this.drawingData?.requestFor3DDrawing?.request3DDrawingStatus) {
      case DrawingRequestStatus.Cancelled:
        return 'red'
      case DrawingRequestStatus.Requested:
        if (this.isRequestCancellable()) return 'orange darken-2'
        return 'yellow darken-2'
      case DrawingRequestStatus.Ready:
        return 'green'
      default:
        return 'grey'
    }
  }

  get drawingRequested() {
    const date = toLocaleDateTimeString(this.drawingData?.requestFor3DDrawing.requested, this.currentLang)
    return date ? date.charAt(0).toUpperCase() + date.slice(1) : ''
  }

  isRequestCancellable() {
    if (!this.drawingData?.requestFor3DDrawing?.requested) return false
    const requestDate = new Date(this.drawingData?.requestFor3DDrawing?.requested)
    const currentDate = new Date()
    const diff = currentDate.getTime() - requestDate.getTime()
    return diff <= this.DAY_IN_MILLISECONDS
  }
}
