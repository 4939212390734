
import { RealEstate, RealEstateExternalActionTargetType } from '@/__generated__/globalTypes'
import QUERIES from '@/queries/queries'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ProjectRealEstatesDocument } from '../projectRealEstates/gql/__generated__/getProjectRealEstates.query'
import {
  ActiveExternalActionDocument,
  ActiveExternalActionQuery,
  ActiveExternalActionQueryVariables
} from './gql/__generated__/activeExternalAction.query'
import {
  CreateExternalActionDocument,
  CreateExternalActionMutation,
  CreateExternalActionMutationVariables
} from './gql/__generated__/createExternalAction.mutation'
import {
  ResolveExternalActionDocument,
  ResolveExternalActionMutation,
  ResolveExternalActionMutationVariables
} from './gql/__generated__/resolveExternalAction.mutation'
import {
  UpdateExternalActionDocument,
  UpdateExternalActionMutation,
  UpdateExternalActionMutationVariables
} from './gql/__generated__/updateExternalAction.mutation'

@Component
export default class ExternalActionDialog extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: null }) realEstate!: RealEstate | null

  MESSAGE_MAX_LENGTH = 1000
  target: RealEstateExternalActionTargetType | null = null
  actionContent = ''
  resolveContent = ''

  isLoading = false

  created() {
    this.fetchPendingAction()
  }

  @Watch('show')
  onVisibilityChange() {
    if (!this.show) {
      this.target = null
      this.actionContent = ''
      this.resolveContent = ''
      this.isLoading = false
    }
  }

  get isNewAction() {
    return !this.realEstate?.activeExternalAction
  }

  get targetOptions() {
    return Object.values(RealEstateExternalActionTargetType).map((target) => ({
      text: this.$t(`global.externalActionTargets.${target}`).toString(),
      value: target
    }))
  }

  get refetchQueries() {
    return [
      {
        query: ProjectRealEstatesDocument,
        variables: { projectId: this.$route?.params?.projectId }
      }
    ]
  }

  get resolveContentErrorMessages() {
    const errors = []

    if (this.resolveContent.length > this.MESSAGE_MAX_LENGTH) {
      errors.push(this.$t('global.forms.textarea.maxLength').toString())
    }

    return errors
  }

  get actionContentErrorMessages() {
    const errors = []

    if (this.actionContent.length > this.MESSAGE_MAX_LENGTH) {
      errors.push(this.$t('global.forms.textarea.maxLength').toString())
    }

    return errors
  }

  get hasErrors() {
    return !!this.actionContentErrorMessages.length || !!this.resolveContentErrorMessages.length
  }

  fetchPendingAction() {
    this.$apollo.addSmartQuery<ActiveExternalActionQuery, ActiveExternalActionQueryVariables>(
      QUERIES.ActiveExternalAction,
      {
        query: ActiveExternalActionDocument,
        variables: () => ({
          realEstateId: this.realEstate?.id ?? ''
        }),
        update: (data) => data,
        result: ({ data }) => {
          const externalAction = data?.realEstate?.activeExternalAction
          this.target = externalAction?.externalActionTarget ?? null
          this.actionContent = externalAction?.notes?.[0]?.content ?? ''
        },
        fetchPolicy: 'network-only',
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4215',
            Message: error.message
          })
        },
        skip: () => !this.realEstate?.id || !this.realEstate?.activeExternalAction
      }
    )
  }

  async createAction() {
    if (this.hasErrors) return

    try {
      this.isLoading = true
      await this.$apolloMutate<CreateExternalActionMutation, CreateExternalActionMutationVariables>({
        mutation: CreateExternalActionDocument,
        variables: {
          realEstateId: this.realEstate?.id ?? '',
          target: this.target ?? RealEstateExternalActionTargetType.Other,
          description: this.actionContent
        },
        refetchQueries: this.refetchQueries,
        error: 'E4216'
      })
    } finally {
      this.isLoading = false
      this.$emit('close')
    }
  }

  async updateAction() {
    if (this.hasErrors) return

    try {
      this.isLoading = true
      await this.$apolloMutate<UpdateExternalActionMutation, UpdateExternalActionMutationVariables>({
        mutation: UpdateExternalActionDocument,
        variables: {
          realEstateId: this.realEstate?.id ?? '',
          target: this.target ?? RealEstateExternalActionTargetType.Other,
          description: this.actionContent
        },
        refetchQueries: this.refetchQueries,
        error: 'E4217'
      })
    } finally {
      this.isLoading = false
      this.$emit('close')
    }
  }

  async resolveAction() {
    try {
      this.isLoading = true
      await this.$apolloMutate<ResolveExternalActionMutation, ResolveExternalActionMutationVariables>({
        mutation: ResolveExternalActionDocument,
        variables: {
          realEstateId: this.realEstate?.id ?? '',
          target: this.target ?? RealEstateExternalActionTargetType.Other,
          description: this.actionContent,
          closingNote: this.resolveContent
        },
        refetchQueries: [
          {
            query: ProjectRealEstatesDocument,
            variables: { projectId: this.$route?.params?.projectId }
          }
        ],
        error: 'E4218'
      })
    } finally {
      this.isLoading = false
      this.$emit('close')
    }
  }
}
