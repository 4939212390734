import { render, staticRenderFns } from "./EditActivityTemplate.vue?vue&type=template&id=c5bdbdb6&scoped=true"
import script from "./EditActivityTemplate.vue?vue&type=script&lang=ts"
export * from "./EditActivityTemplate.vue?vue&type=script&lang=ts"
import style0 from "./EditActivityTemplate.vue?vue&type=style&index=0&id=c5bdbdb6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5bdbdb6",
  null
  
)

export default component.exports