
import { Component, Prop, Vue } from 'vue-property-decorator'
import ActivityCostField from '../activities/ActivityCostField.vue'
import { CostType } from '@/__generated__/globalTypes'
import { DataOptions, DataTableHeader } from 'vuetify'
import { AssignedActivity } from '@/components/activities/types'
import { getDisplayPrice, getInputPrice } from '@/utils/currencyUtils'
import {
  OrderOverviewActivitiesDocument,
  OrderOverviewActivitiesQuery,
  OrderOverviewActivitiesQueryVariables,
  OrderOverviewActivityFragment
} from './gql/__generated__/orderOverviewActivities.query'
import QUERIES from '@/queries/queries'
import { OrderActivityCost } from '@/components/roomSelection/types'

@Component({
  components: {
    ActivityCostField
  }
})
export default class OrderOverviewActivitiesList extends Vue {
  @Prop() productSetActivities!: OrderActivityCost[]

  search = ''
  projectActivities: OrderOverviewActivityFragment[] = []
  tableOptions: DataOptions = {} as DataOptions
  CostType = CostType

  costTypeSlots = [
    CostType.WInstallationCosts,
    CostType.CvInstallationCosts,
    CostType.EInstallationCosts,
    CostType.InstallationConstructionCosts,
    CostType.TilerInstallationCosts,
    CostType.KitterInstallationCosts
  ]

  created() {
    // query deduplication and caching ensure that is query is only executed against the server once instead of for every category
    this.$apollo.addSmartQuery<OrderOverviewActivitiesQuery, OrderOverviewActivitiesQueryVariables>(
      QUERIES.OrderOverviewActivities,
      {
        query: OrderOverviewActivitiesDocument,
        variables: () => ({
          lang: this.lang
        }),
        update: (data) => data,
        result: (result) => {
          this.projectActivities = result.data?.projectActivities ?? []
        },
        fetchPolicy: 'cache-first',
        skip: () => !this.productSetActivities.length
      }
    )
  }

  get lang() {
    return this.$i18n.locale
  }

  get headers() {
    const headers: DataTableHeader[] = [
      {
        text: this.$t('activities.table.headers.combination').toString(),
        value: 'activity.productCombination.position',
        groupable: true,
        width: '10%',
        filterable: false
      },
      {
        text: this.$t('activities.table.headers.activity').toString(),
        value: 'activity.activityName',
        width: '25%'
      },
      // Hidden column so that user can filter/search on the positionLabel
      { text: '', value: 'activity.positionLabel', class: 'd-none', cellClass: 'd-none' }
    ]

    this.costTypeSlots.forEach((costType) => {
      headers.push({
        text: this.$t(`activities.table.headers.${costType}`).toString(),
        value: costType,
        width: '10%',
        sortable: false,
        filterable: false
      })
    })

    return headers
  }

  get tableData(): AssignedActivity[] {
    if (!this.projectActivities.length) return []

    return this.productSetActivities.map((pSetActivity) => {
      // The order overview only contains the activity ids and costs. The activity text content is fetched in this component.
      const activity = this.projectActivities.find((x) => x.id === pSetActivity.activityId)

      return {
        activity: {
          id: pSetActivity.activityId,
          position: activity?.position ?? 0,
          positionLabel: activity?.positionLabel ?? '',
          activityName: activity?.activityName ?? '',
          productCombination: {
            name:
              this.$t(
                `global.combinations.${activity?.productCombination?.combinationType?.toLowerCase()}`
              ).toString() ?? '',
            position: activity?.productCombination?.position ?? 0
          }
        },
        W_INSTALLATION_COSTS: this.getCostTypeValue(pSetActivity, CostType.WInstallationCosts),
        E_INSTALLATION_COSTS: this.getCostTypeValue(pSetActivity, CostType.EInstallationCosts),
        CV_INSTALLATION_COSTS: this.getCostTypeValue(pSetActivity, CostType.CvInstallationCosts),
        INSTALLATION_CONSTRUCTION_COSTS: this.getCostTypeValue(pSetActivity, CostType.InstallationConstructionCosts),
        TILER_INSTALLATION_COSTS: this.getCostTypeValue(pSetActivity, CostType.TilerInstallationCosts),
        KITTER_INSTALLATION_COSTS: this.getCostTypeValue(pSetActivity, CostType.KitterInstallationCosts)
      }
    })
  }

  getCostTypeValue(activity: OrderActivityCost, costType: CostType) {
    const cost = activity.costs.find((x) => x?.type === costType)?.amount

    // use regex to remove thousands separator
    return cost ? getInputPrice(cost).replace(/\./g, '') : ''
  }

  sumCostType(costType: string) {
    const sum = this.tableData.reduce((sum, current) => {
      // gets the cost value for this cost type, using english decimal separator
      const cost = current[costType as keyof AssignedActivity]?.toString().replace(',', '.')
      return sum + (Number(cost) || 0)
    }, 0)
    return getDisplayPrice(sum)
  }

  get areActivitiesGrouped() {
    return !!this.tableOptions.groupBy.length
  }

  get costHeaders() {
    return this.headers.filter((header) => Object.values(CostType).includes(header.value as CostType))
  }

  onSortChange() {
    // grouping the activities after ungroup sometimes shifts the rows, so ensure that the right sorting is applied.
    if (this.areActivitiesGrouped) {
      this.tableOptions.sortBy = ['activity.position']
      this.tableOptions.sortDesc = [false]
    }
  }

  expandAllCombinations() {
    const categoriesToExpand = document.querySelectorAll('[data-js="expand-category"]')
    const clickEvent = new MouseEvent('click')

    categoriesToExpand.forEach((item) => item.dispatchEvent(clickEvent))
  }
}
