
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import {
  AddCustomOfferRequestAppointmentMutation,
  AddCustomOfferRequestAppointmentMutationVariables,
  AddCustomOfferRequestAppointmentDocument
} from './gql/__generated__/addCustomOfferRequestAppointment.mutation'
import { CustomOfferAppointmentType } from '@/__generated__/globalTypes'
import QUERIES from '@/queries/queries'
import { AppointmentItem } from './appointmentType'
import {
  UpdateCustomOfferRequestAppointmentMutation,
  UpdateCustomOfferRequestAppointmentMutationVariables,
  UpdateCustomOfferRequestAppointmentDocument
} from './gql/__generated__/updateCustomOfferRequestAppointment.mutation'

@Component({
  components: {}
})
export default class AppointmentModal extends Vue {
  @Prop() show!: boolean
  @Prop() customOfferRequestId!: string
  @Prop({ required: false }) appointment!: AppointmentItem

  isLoading = false
  appointmentDate = ''
  appointmentTime = ''
  isValidFormat = true
  selectedType = ''

  @Ref() appointmentForm?: HTMLFormElement

  @Watch('show')
  onshowUpdate() {
    this.appointmentForm?.reset()
  }

  @Watch('show')
  onVisibilityChange() {
    if (this.show && this.appointment) {
      this.appointmentTime = this.appointment.time
      this.appointmentDate = this.appointment.date
      this.selectedType = this.appointment.type
    }
  }

  get isEditDisabled() {
    return this.isLoading
  }

  get isAddDisabled() {
    return this.isLoading || !this.appointmentDate || !this.appointmentTime || !this.selectedType
  }

  get rules() {
    return {
      isValidDateFormat: (value: string) => {
        const timeRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
        this.isValidFormat = timeRegex.test(value)
        return timeRegex.test(value) || this.$t('customOfferDetails.addAppointmentModal.invalidDateFormat')
      },
      isValidTimeFormat: (value: string) => {
        const timeRegex = /^(0\d|1\d|2[0-3]|[0-9]):[0-5][0-9]$/
        this.isValidFormat = timeRegex.test(value)
        return timeRegex.test(value) || this.$t('customOfferDetails.addAppointmentModal.invalidTimeFormat')
      }
    }
  }

  get dateTime() {
    return new Date(
      (this.appointmentDate.length < 1 ? this.appointment.date : this.appointmentDate) +
        ' ' +
        (this.appointmentTime.length < 1 ? this.appointment.time : this.appointmentTime)
    ).toISOString()
  }

  get appointmentType() {
    return this.selectedType.length < 1 ? this.appointment.type : this.selectedType
  }

  editAppointment() {
    this.isLoading = true
    this.$apolloMutate<
      UpdateCustomOfferRequestAppointmentMutation,
      UpdateCustomOfferRequestAppointmentMutationVariables
    >({
      mutation: UpdateCustomOfferRequestAppointmentDocument,
      variables: {
        customOfferRequestId: this.customOfferRequestId,
        customOfferRequestAppointmentId: this.appointment.id,
        appointmentDate: this.dateTime,
        appointmentType: this.appointmentType as CustomOfferAppointmentType
      },
      refetchQueries: [QUERIES.CustomOfferRequest],
      error: 'E1114'
    })
      .then(() => this.$emit('close'))
      .finally(() => (this.isLoading = false))
  }

  get customOfferRequestGlobalAppointmentTypesTranslated() {
    return Object.values(CustomOfferAppointmentType)
      .filter((type) => type !== CustomOfferAppointmentType.NoCosts)
      .map((type) => ({
        text: this.$t(`global.customOfferAppointmentTypes.${type}`).toString(),
        value: type
      }))
  }

  async saveAppointment() {
    if (!this.appointmentForm?.validate()) return

    this.isLoading = true
    const newAppointment = await this.$apolloMutate<
      AddCustomOfferRequestAppointmentMutation,
      AddCustomOfferRequestAppointmentMutationVariables
    >({
      mutation: AddCustomOfferRequestAppointmentDocument,
      variables: {
        customOfferRequestId: this.customOfferRequestId,
        appointmentDate: this.dateTime,
        appointmentType: this.selectedType as CustomOfferAppointmentType
      },
      error: 'E1112'
    })
    this.isLoading = false

    if (newAppointment !== false) {
      const newlyCreatedAppointment = newAppointment
      if (!newlyCreatedAppointment) return

      this.$emit('new-appointment-created', newlyCreatedAppointment)
      this.$emit('refetch-custom-offer-request-query')
      this.$emit('close')
    }
  }
}
