
import {
  DesignPackageProductSetRestrictionInfoKey,
  ProductCombinationType,
  ProductSetInfoKeyType,
  Category,
  Badkamercore_ProjectType
} from '@/__generated__/globalTypes'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import AreaMeasurementField from './AreaMeasurementField.vue'
import BoolField from './BoolField.vue'
import DecimalField from './DecimalField.vue'
import DefaultProductSet from './DefaultProductSet.vue'
import PreselectedProductSet from './PreselectedProductSet.vue'
import EnumField from './EnumField.vue'
import LengthMeasurementField from './LengthMeasurementField.vue'
import MultiEnumField from './MultiEnumField.vue'
import NumberField from './NumberField.vue'
import PercentageField from './PercentageField.vue'
import WallManagementComponent from './WallManagementComponent.vue'
import {
  CategoriesWithAssignedProductSetsAndProjectDefaults,
  GuidEmpty,
  ProductSetInfoKeyComponentMapping,
  SelectedCombination,
  AlwaysDisabledCategories
} from './types'
import { ProductSetInfoKeyDataType } from '@/__generated__/globalTypes'
import { isOptionalButMandatoryCategory } from '@/utils/validationUtils'
const defaultToMeter: ProductSetInfoKeyType[] = [
  ProductSetInfoKeyType.ToiletSurroundLinear,
  ProductSetInfoKeyType.StandardWallTileHeight
]

const disabledMeasureUnitSelection: ProductSetInfoKeyType[] = [
  ProductSetInfoKeyType.ToiletSurroundLinear,
  ProductSetInfoKeyType.StandardWallTileHeight
]

@Component({
  name: 'ProductCombinationConfiguration',
  components: {
    BoolField,
    EnumField,
    MultiEnumField,
    NumberField,
    DecimalField,
    PercentageField,
    LengthMeasurementField,
    AreaMeasurementField,
    DefaultProductSet,
    PreselectedProductSet,
    WallManagementComponent
  }
})
export default class ProductCombinationConfiguration extends Vue {
  @Prop({ required: true }) value!: SelectedCombination
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) v!: any
  @Prop() disabled!: boolean
  @Prop({ required: true }) isPriceVariant!: boolean
  @Prop({ required: true }) isTilesToCeiling!: boolean
  @Prop({ required: true }) projectType!: Badkamercore_ProjectType
  @Prop({ required: true }) categoriesCMS!: Category[] | undefined

  ProductSetInfoKeyType = ProductSetInfoKeyType
  isOptionalButMandatoryCategory = isOptionalButMandatoryCategory

  get designPackageProductSetRestrictionInfoKeysArray() {
    return (this.value.combinationInfo?.designPackageProductSetRestrictionInfoKeys ??
      []) as DesignPackageProductSetRestrictionInfoKey[]
  }

  get isTiles() {
    return this.value.combinationType === ProductCombinationType.Tiles
  }
  get assignedWalls() {
    return this.value.walls ?? []
  }
  get getAltText() {
    return this.$t(`global.combinations.${(this.value.combinationType as string).toLowerCase()}`).toString()
  }

  get getCombinationIcon() {
    const iconName = (this.value.combinationType as string).toLowerCase() + '.svg'
    return require('@/assets/product_combinations/' + iconName)
  }

  get getCombinationConfigurationTitle() {
    return `${this.$t('projectDesignPackage.create.configure').toString()} ${this.$t(
      'global.combinations.' + (this.value.combinationType as string).toLowerCase()
    )
      .toString()
      .toLowerCase()}`
  }

  /**
   * Checks wether the category should (temporarily) always be disabled. We do this to avoid adding complex back-end calculations that are very unlikely to be needed.
   * @param category The category
   */
  isCategoryAlwaysDisabled(category: CategoriesWithAssignedProductSetsAndProjectDefaults) {
    return [AlwaysDisabledCategories.ShowerPartitionWallTiles, AlwaysDisabledCategories.BathCasingTiles].includes(
      category.productCategory?.id
    )
  }

  getCategoryCMS(category: CategoriesWithAssignedProductSetsAndProjectDefaults): Category | undefined {
    return this.categoriesCMS?.find((cat) => category.productCategory?.id == cat?.alias?.alias)
  }

  getComponentName(designPackageProductSetRestrictionInfoKeys: DesignPackageProductSetRestrictionInfoKey) {
    const dataType = designPackageProductSetRestrictionInfoKeys.productSetInfoKey?.dataType
    return dataType ? ProductSetInfoKeyComponentMapping[dataType] : ''
  }

  isMeasurementUnitSelectionDisabled(
    designPackageProductSetRestrictionInfoKeys: DesignPackageProductSetRestrictionInfoKey
  ) {
    if (
      designPackageProductSetRestrictionInfoKeys.productSetInfoKey?.dataType ==
        ProductSetInfoKeyDataType.LengthMeasurement &&
      !disabledMeasureUnitSelection.includes(
        designPackageProductSetRestrictionInfoKeys?.productSetInfoKey?.key ?? ProductSetInfoKeyType.NotSet
      )
    )
      return false
    return true
  }

  getDefaultMeasure(
    designPackageProductSetRestrictionInfoKeys: DesignPackageProductSetRestrictionInfoKey
  ): string | undefined {
    if (
      defaultToMeter.includes(
        designPackageProductSetRestrictionInfoKeys?.productSetInfoKey?.key ?? ProductSetInfoKeyType.NotSet
      )
    )
      return 'm'
  }

  getRestrictionIndex(item: DesignPackageProductSetRestrictionInfoKey) {
    return this.designPackageProductSetRestrictionInfoKeysArray.indexOf(item)
  }

  get defaultProductSetsWalls() {
    const wallAccentTileCategory = this.value.categoriesWithAssignedProductSets.find(
      (x) => x.productCategory?.isAccentWallTiles
    )
    if (!wallAccentTileCategory) return []

    return this.getDefaultProductSets(wallAccentTileCategory)
  }

  get preselectedProductSetsWalls() {
    const wallAccentTileCategory = this.value.categoriesWithAssignedProductSets.find(
      (x) => x.productCategory?.isAccentWallTiles
    )
    if (!wallAccentTileCategory) return []

    return this.getPreselectedProductSets(wallAccentTileCategory)
  }

  getDefaultProductSets(category: CategoriesWithAssignedProductSetsAndProjectDefaults) {
    // If we don't have to load product sets from other categories, then just use the product sets from this category
    if (
      !category.productCategory?.loadProductSetsFromCategoryId &&
      !category.productCategory?.loadProductSetsFromAdditionalCategoryId
    )
      return category.assignedProductSets?.items ?? []

    // Otherwise, get the product sets from the loadProductSetsFromCategoryId and loadProductSetsFromAdditionalCategoryId
    const productSetsFromOtherCategory =
      this.value.categoriesWithAssignedProductSets?.find(
        (x) => x.productCategory?.id === category.productCategory?.loadProductSetsFromCategoryId
      )?.assignedProductSets?.items ?? []
    const productSetsFromAdditionalOtherCategory =
      this.value.categoriesWithAssignedProductSets?.find(
        (x) => x.productCategory?.id === category.productCategory?.loadProductSetsFromAdditionalCategoryId
      )?.assignedProductSets?.items ?? []

    const dummyOption = []
    if (category.productCategory.isSelectionOptional && isOptionalButMandatoryCategory(category.productCategory.id)) {
      dummyOption.push({
        productSet: { id: GuidEmpty, name: this.$t('projectDesignPackage.labels.walls.defaultWallTileLabel') }
      })
    }

    return this.removeDuplicatesWithfilter([
      ...dummyOption,
      ...productSetsFromOtherCategory,
      ...productSetsFromAdditionalOtherCategory
    ])
  }

  getPreselectedProductSets(category: CategoriesWithAssignedProductSetsAndProjectDefaults) {
    const dummyOption = []
    if (
      category?.productCategory?.isSelectionOptional &&
      isOptionalButMandatoryCategory(category?.productCategory?.id)
    ) {
      dummyOption.push({
        productSet: { id: GuidEmpty, name: this.$t('projectDesignPackage.labels.walls.preselectedWallTileLabel') }
      })
    }
    return [...dummyOption, ...(category.assignedPreselectedProductSets?.items ?? [])]
  }

  // Because the infoKey BASIC_INCLUDED is always provided for every category, we only want to show it for the first one
  // Otherwise we will end up with a couple of duplicated fields
  showRestriction(restriction: DesignPackageProductSetRestrictionInfoKey) {
    if (restriction.productSetInfoKey?.key !== ProductSetInfoKeyType.BasicIncluded) return true

    // get all BASIC_INCLUDED
    const firstBasicIncluded = this.designPackageProductSetRestrictionInfoKeysArray.find(
      (item) => item.productSetInfoKey?.key === ProductSetInfoKeyType.BasicIncluded
    )

    // if the infokey is BASIC_INCLUDED, only show the first one (because it appears for every product set group)
    return firstBasicIncluded?.id === restriction.id
  }

  get basicInRoom() {
    const restriction = this.designPackageProductSetRestrictionInfoKeysArray.find(
      (x) => x.productSetInfoKey?.key === ProductSetInfoKeyType.BasicIncluded
    )

    if (!restriction) return false

    return this.value.values[this.getRestrictionIndex(restriction)].item2
  }

  @Watch('basicInRoom')
  onBasicInRoomChange() {
    const masterRestriction = this.designPackageProductSetRestrictionInfoKeysArray.find(
      (x) => x.productSetInfoKey?.key === ProductSetInfoKeyType.BasicIncluded
    )

    const restrictions = this.designPackageProductSetRestrictionInfoKeysArray.filter(
      (x) => x.productSetInfoKey?.key === ProductSetInfoKeyType.BasicIncluded && x.id != masterRestriction?.id
    )

    restrictions.forEach((element) => {
      this.value.values[this.getRestrictionIndex(element)].item2 = `${this.basicInRoom}`
    })
  }

  removeDuplicatesWithfilter(arr: Array<unknown>): Array<unknown> {
    const outputArray = arr.filter(function (v, i, self) {
      // It returns the index of the first
      // instance of each value
      return i == self.indexOf(v)
    })

    return outputArray
  }
}
