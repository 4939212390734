import { render, staticRenderFns } from "./ProductPackageView.vue?vue&type=template&id=0a6b17ee&scoped=true"
import script from "./ProductPackageView.vue?vue&type=script&lang=ts"
export * from "./ProductPackageView.vue?vue&type=script&lang=ts"
import style0 from "./ProductPackageView.vue?vue&type=style&index=0&id=0a6b17ee&prod&lang=css"
import style1 from "./ProductPackageView.vue?vue&type=style&index=1&id=0a6b17ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6b17ee",
  null
  
)

export default component.exports