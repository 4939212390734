
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { RealEstate } from '@/__generated__/globalTypes'
import {
  CreateNoteDocument,
  CreateNoteMutation,
  CreateNoteMutationVariables
} from './gql/__generated__/createNote.mutation'

@Component
export default class NoteDialog extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: null }) realEstate!: RealEstate | null

  MESSAGE_MAX_LENGTH = 1000
  note = ''
  isLoading = false

  @Watch('show')
  onShowChange() {
    if (!this.show) {
      this.note = ''
    }
  }

  get noteErrors() {
    const errors = []

    if (this.note.length > this.MESSAGE_MAX_LENGTH) {
      errors.push(this.$t('global.forms.textarea.maxLength').toString())
    }

    return errors
  }

  async createNote() {
    try {
      this.isLoading = true
      await this.$apolloMutate<CreateNoteMutation, CreateNoteMutationVariables>({
        mutation: CreateNoteDocument,
        variables: {
          realEstateId: this.realEstate?.id,
          note: this.note
        },
        error: 'E4243'
      })
    } finally {
      this.isLoading = false
      this.$emit('close')
    }
  }
}
