
import {
  Badkamercore_ProjectType,
  Project,
  ProjectStatus,
  RealEstate,
  RealEstateStatus,
  SelectionType
} from '@/__generated__/globalTypes'
import ExternalActionDialog from '@/components/externalActionDialog/ExternalActionDialog.vue'
import NoteDialog from '@/components/noteDialog/NoteDialog.vue'
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import { toLocaleDateString } from '@/utils/dateUtils'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import RealEstateDocumentsDialog from './RealEstateDocumentsDialog.vue'
import {
  ForceRealEstateSelectionDocument,
  ForceRealEstateSelectionMutation,
  ForceRealEstateSelectionMutationVariables
} from './gql/__generated__/forceRealEstateSelection.mutation'
import {
  ExternalPreviewTokenDocument,
  ExternalPreviewTokenQuery,
  ExternalPreviewTokenQueryVariables
} from './gql/__generated__/getExternalPreviewToken.query'
import {
  PreviewTokenDocument,
  PreviewTokenQuery,
  PreviewTokenQueryVariables
} from './gql/__generated__/getPreviewToken.query'
import {
  ProjectRealEstatesDocument,
  ProjectRealEstatesQuery,
  ProjectRealEstatesQueryVariables
} from './gql/__generated__/getProjectRealEstates.query'
import {
  ResetDesignPackageSelectionDocument,
  ResetDesignPackageSelectionMutation,
  ResetDesignPackageSelectionMutationVariables
} from './gql/__generated__/resetDesignPackageSelection.mutation'
import {
  ResetRealEstateSelectionsDocument,
  ResetRealEstateSelectionsMutation,
  ResetRealEstateSelectionsMutationVariables
} from './gql/__generated__/resetRealEstateSelections.mutation'
import {
  SendInvitationToRealEstatesDocument,
  SendInvitationToRealEstatesMutation,
  SendInvitationToRealEstatesMutationVariables
} from './gql/__generated__/sendInvitations.mutation'
import {
  SendNoQuoteNotificationDocument,
  SendNoQuoteNotificationMutation,
  SendNoQuoteNotificationMutationVariables
} from './gql/__generated__/sendNoQuoteNotification.mutation'
import {
  SendNoLoginNotificationDocument,
  SendNoLoginNotificationMutation,
  SendNoLoginNotificationMutationVariables
} from './gql/__generated__/sendNonLoginNotification.mutation'
import {
  UpdateRealEstateDisableNotificationsDocument,
  UpdateRealEstateDisableNotificationsMutation,
  UpdateRealEstateDisableNotificationsMutationVariables
} from './gql/__generated__/updateRealEstateDisableNotifications.mutation'

type RealEstateItem = {
  id: string
  status: string
  statusChangedDisplayDate: string
  offerNumber: string
  type: string
  email: string
  invitationSent: number
  invitationSentDisplayDate: string
  lastLogin: number
  lastLoginDisplayDate: string
  hasRequestForQuote: boolean
  deadline: number
  deadLineDisplayDate: string
  item: RealEstate
  inviteCheckbox: boolean
  formalName: string
  hasAdditionalWork: boolean
  contactDetail: string
  notificationsDisabled: boolean
  customOfferStatus: string
  customOfferAppointmentDate: number
  customOfferAppointmentDisplayDate: string
  confirmedOrderType: string
  confirmedOrderDisplayDate: string
  dossierSent: number
  dossierSentDisplayDate: string
  activeExternalActionTarget: string
}

@Component({
  components: {
    RealEstateDocumentsDialog,
    ExternalActionDialog,
    NoteDialog
  }
})
export default class ProjectRealEstates extends Vue {
  @Prop() projectId!: string
  project: Project | null = null
  search = ''
  selectedRealEstate: RealEstate | null = null
  checkAllRealEstates = false
  isLoading = false
  showExternalActionPopup = false
  showAddNotePopup = false
  showUploadDocumentsDialog = false
  isSendDossierDialog = false

  ProjectType = Badkamercore_ProjectType
  RouteNames = RouteNames
  RealEstateStatus = RealEstateStatus

  created() {
    this.$apollo.addSmartQuery<ProjectRealEstatesQuery, ProjectRealEstatesQueryVariables>(
      QUERIES.ProjectRealEstatesQuery,
      {
        query: ProjectRealEstatesDocument,
        fetchPolicy: 'network-only',
        variables: { projectId: this.projectId },
        result: (result) => {
          this.project = result.data.project as Project
        },
        update: (data) => data,
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4112',
            Message: error.message
          })
        }
      }
    )
  }

  get isReadonly() {
    return !this.$store.getters.isAdmin
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: '',
        value: 'invitationCheckbox',
        sortable: false,
        class: 'padding-1',
        cellClass: `padding-1 ${
          !this.isInviteEnabled ? 'c-project-real-estates__realestate-checkbox-cell-disabled' : ''
        }`,
        width: '2%'
      },
      {
        text: '',
        value: 'externalAction',
        sortable: false,
        align: 'center',
        class: 'px-0',
        cellClass: 'px-0',
        width: '2%'
      },
      {
        text: this.$t('projectRealEstates.table.offerNumber').toString(),
        class: 'pl-0',
        cellClass: 'pl-0',
        value: 'offerNumber',
        width: '7%'
      },
      { text: this.$t('projectRealEstates.table.buildNumber').toString(), value: 'buildNumber', width: '7%' },
      { text: this.$t('projectRealEstates.table.layout').toString(), value: 'type', width: '8%' },
      { text: this.$t('projectRealEstates.table.status.label').toString(), value: 'status', width: '8%' },
      { text: this.$t('projectRealEstates.table.contactDetail').toString(), value: 'contactDetail', width: '10%' },
      {
        text: this.$t('projectRealEstates.table.invitationSent').toString(),
        align: 'center',
        value: 'invitationSent',
        width: '8%'
      },
      {
        text: this.$t('projectRealEstates.table.lastLogin').toString(),
        align: 'center',
        value: 'lastLogin',
        width: '8%'
      },
      {
        text: this.$t('projectRealEstates.table.customOffer.label').toString(),
        align: 'center',
        value: 'customOfferStatus',
        width: '8%'
      },
      {
        text: this.$t('projectRealEstates.table.hasNotifications').toString(),
        align: 'center',
        value: 'hasNotifications',
        width: '8%'
      },
      {
        text: this.$t('projectRealEstates.table.deadline').toString(),
        value: 'deadline',
        width: '8%'
      },
      {
        text: this.$t('projectRealEstates.table.hasRequestForQuote').toString(),
        align: 'center',
        value: 'hasRequestForQuote',
        width: '8%'
      },
      {
        text: this.$t('projectRealEstates.table.dossierSent').toString(),
        align: 'center',
        value: 'dossierSent',
        width: '7%'
      },
      { text: '', value: 'actions', sortable: false, align: 'end', width: '1%' }
    ]
  }

  get realEstateItems(): RealEstateItem[] {
    if (!this.project) {
      return []
    }
    return this.project.realEstates
      .sort((leftSide, rightSide): number => {
        if (leftSide.number < rightSide.number) return -1
        if (leftSide.number > rightSide.number) return 1
        return 0
      })
      .map<RealEstateItem>((x) => {
        return {
          item: x,
          id: x.id,
          status: x.activeStatus?.status
            ? this.$t(`global.realEstateStatuses.${x.activeStatus.status}`).toString()
            : '',
          statusChangedDisplayDate: toLocaleDateString(x.activeStatus?.updateDate, this.language) ?? '',
          offerNumber: x.number,
          buildNumber: x.buildNumber,
          type: x.layout?.name ?? '',
          email: x.contact?.email?.address ?? '',
          invitationSent: Date.parse(x.contact?.invitationSent) || Number.MAX_SAFE_INTEGER,
          invitationSentDisplayDate: toLocaleDateString(x.contact?.invitationSent, this.language) ?? '',
          lastLogin: Date.parse(x.contact?.lastLogin) || Number.MAX_SAFE_INTEGER,
          lastLoginDisplayDate: toLocaleDateString(x.contact?.lastLogin, this.language) ?? '',
          hasRequestForQuote: x.requestForQuotes?.length > 0,
          deadline: Date.parse(x.closeDateUtc) || Number.MAX_SAFE_INTEGER,
          deadLineDisplayDate: toLocaleDateString(x.closeDateUtc, this.language) ?? '',
          inviteCheckbox: this.checkAllRealEstates ?? false,
          formalName: x.contact?.formalName ?? '',
          hasAdditionalWork: !!x.hasAdditionalWork,
          contactDetail: `${x.contact?.formalName ?? ''} ${x.contact?.email?.address ?? ''}`,
          notificationsDisabled: !!x.contact?.notificationsDisabled,
          customOfferStatus: x.customOfferRequest?.requestStatus
            ? this.$t(`global.customOfferStatuses.${x.customOfferRequest.requestStatus.status}`).toString()
            : '',
          customOfferAppointmentDate: Date.parse(x.customOfferRequest?.appointmentDate) || Number.MAX_SAFE_INTEGER,
          customOfferAppointmentDisplayDate: toLocaleDateString(x.contact?.invitationSent, this.language) ?? '',
          confirmedOrderType: x.realEstateOrder?.confirmedStatusType
            ? this.$t(
                `projectRealEstates.confirmedStatus.${x.realEstateOrder.confirmedStatusType.toLowerCase()}`
              ).toString()
            : '',
          confirmedOrderDisplayDate: toLocaleDateString(x.realEstateOrder?.createdDate, this.language) ?? '',
          dossierSent: Date.parse(x.dossierSentDateUtc) || Number.MAX_SAFE_INTEGER,
          dossierSentDisplayDate: toLocaleDateString(x.dossierSentDateUtc, this.language) ?? '',
          activeExternalActionTarget: x.activeExternalAction?.externalActionTarget
            ? this.$t(`global.externalActionTargets.${x.activeExternalAction?.externalActionTarget}`).toString()
            : ''
        }
      })
  }

  get realEstateCount(): number {
    if (!this.project) {
      return 0
    }
    return this.project.realEstates.length
  }

  get isInviteEnabled() {
    return this.project?.status === ProjectStatus.Open && !this.isReadonly
  }

  get language() {
    return this.$i18n.locale
  }

  canSendDossier(item: RealEstateItem) {
    return (
      !!item.item.activeStatus?.status &&
      [RealEstateStatus.SendDossier, RealEstateStatus.Completed].includes(item.item.activeStatus.status)
    )
  }

  clickPreview(item: RealEstateItem) {
    this.$apollo
      .query<PreviewTokenQuery, PreviewTokenQueryVariables>({
        query: PreviewTokenDocument,
        variables: {
          reid: item.id
        }
      })
      .then((result) => {
        const previewToken = result.data.previewToken
        window.open(`${process.env.VUE_APP_APP_URL}/?token=${previewToken}`)
      })
      .catch((error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4113',
          Message: error,
          Sub: 'A'
        })
      })
  }

  clickRealEstateOrder(item: RealEstateItem) {
    const route = this.$router.resolve({
      name: RouteNames.REAL_ESTATE_ORDER,
      params: {
        projectId: this.projectId,
        realEstateId: item.id
      }
    })
    window.open(route.href, '_blank')
  }

  clickExternalPreview(item: RealEstateItem) {
    this.$apollo
      .query<ExternalPreviewTokenQuery, ExternalPreviewTokenQueryVariables>({
        query: ExternalPreviewTokenDocument,
        variables: {
          reid: item.id
        }
      })
      .then((result) => {
        const externalPreviewToken = result.data.externalPreviewToken
        const externalPreviewLink = `${process.env.VUE_APP_APP_URL}/?token=${externalPreviewToken}`

        navigator.clipboard.writeText(externalPreviewLink)

        this.$store.dispatch('showNotificationDialog', {
          Code: 'N4008'
        })
      })
      .catch((error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4113',
          Message: error,
          Sub: 'B'
        })
      })
  }

  clickResetOrder(item: RealEstateItem) {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmResetOrder(item),
      Code: 'C4009'
    })
  }

  clickResetSelections(item: RealEstateItem) {
    if (this.isReadonly || item.hasRequestForQuote) return

    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmResetSelections(item),
      Code: 'C4015'
    })
  }

  confirmResetOrder(item: RealEstateItem) {
    this.$store.dispatch('hideConfirmationDialog')

    this.$apolloMutate<ResetDesignPackageSelectionMutation, ResetDesignPackageSelectionMutationVariables>({
      mutation: ResetDesignPackageSelectionDocument,
      variables: {
        realEstateId: item.id
      },
      refetchQueries: [
        {
          query: ProjectRealEstatesDocument,
          variables: { projectId: this.projectId }
        }
      ],
      error: 'E4133'
    })
  }

  confirmResetSelections(item: RealEstateItem) {
    this.$store.dispatch('hideConfirmationDialog')

    this.$apolloMutate<ResetRealEstateSelectionsMutation, ResetRealEstateSelectionsMutationVariables>({
      mutation: ResetRealEstateSelectionsDocument,
      variables: {
        realEstateId: item.id
      },
      refetchQueries: [
        {
          query: ProjectRealEstatesDocument,
          variables: { projectId: this.projectId }
        }
      ],
      error: 'E4199'
    })
  }

  toRealEstateDetails(item: RealEstateItem) {
    this.$router.push({ name: RouteNames.REAL_ESTATE_DETAILS, params: { id: item.id } })
  }

  sendInvitations() {
    this.isLoading = true
    const realEstateIdsToInvite = this.realEstateItems.filter((x) => !!x.inviteCheckbox).map((y) => y.id)

    this.$apolloMutate<SendInvitationToRealEstatesMutation, SendInvitationToRealEstatesMutationVariables>({
      mutation: SendInvitationToRealEstatesDocument,
      variables: { realEstateIds: realEstateIdsToInvite },
      refetchQueries: [
        {
          query: ProjectRealEstatesDocument,
          variables: { projectId: this.projectId }
        }
      ],
      error: 'E4172'
    }).finally(() => {
      this.isLoading = false
    })
  }

  hasNotifications(item: RealEstateItem) {
    const loginNotificationDate = item.item.contact?.lastNoLoginNotification
    const quoteNotificationDate = item.item.contact?.lastNoQuoteNotification
    if (loginNotificationDate || quoteNotificationDate) {
      return true
    }

    return false
  }

  getNotificationsTooltipNoLogin(item: RealEstateItem) {
    const lastNotification = item.item.contact?.lastNoLoginNotification
    if (lastNotification) {
      return `${this.$t(`projectRealEstates.labels.lastNoLoginNotification`).toString()}: ${toLocaleDateString(
        lastNotification.sentDate,
        this.language
      )}`
    }
    return null
  }

  getNotificationsTooltipNoQuote(item: RealEstateItem) {
    const lastNotification = item.item.contact?.lastNoQuoteNotification
    if (lastNotification) {
      return `${this.$t(`projectRealEstates.labels.lastNoQuoteNotification`).toString()}: ${toLocaleDateString(
        lastNotification.sentDate,
        this.language
      )}`
    }
    return null
  }

  updateNotificationsDisabled(item: RealEstateItem) {
    return this.$apolloMutate<
      UpdateRealEstateDisableNotificationsMutation,
      UpdateRealEstateDisableNotificationsMutationVariables
    >({
      mutation: UpdateRealEstateDisableNotificationsDocument,
      variables: {
        realEstatId: item.id,
        disabled: !item.notificationsDisabled
      },
      refetchQueries: [
        {
          query: ProjectRealEstatesDocument,
          variables: { projectId: this.projectId }
        }
      ],
      error: 'E4201'
    })
  }

  clickActionWithConfirmation(
    item: RealEstateItem,
    messageCode: string,
    confirmAction: (item: RealEstateItem) => void
  ) {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => {
        this.$store.dispatch('hideConfirmationDialog')
        confirmAction(item)
      },
      Code: messageCode
    })
  }

  confirmSendNoQuoteNotification(item: RealEstateItem) {
    this.isLoading = true
    this.$apolloMutate<SendNoQuoteNotificationMutation, SendNoQuoteNotificationMutationVariables>({
      mutation: SendNoQuoteNotificationDocument,
      variables: { realEstateId: item.id },
      refetchQueries: [
        {
          query: ProjectRealEstatesDocument,
          variables: { projectId: this.projectId }
        }
      ],
      error: 'E4202'
    }).finally(() => {
      this.isLoading = false
    })
  }

  confirmSendNoLoginNotification(item: RealEstateItem) {
    this.isLoading = true
    this.$apolloMutate<SendNoLoginNotificationMutation, SendNoLoginNotificationMutationVariables>({
      mutation: SendNoLoginNotificationDocument,
      variables: { realEstateId: item.id },
      refetchQueries: [
        {
          query: ProjectRealEstatesDocument,
          variables: { projectId: this.projectId }
        }
      ],
      error: 'E4202'
    }).finally(() => {
      this.isLoading = false
    })
  }

  confirmSelectShellOnly(item: RealEstateItem) {
    this.isLoading = true
    this.$apolloMutate<ForceRealEstateSelectionMutation, ForceRealEstateSelectionMutationVariables>({
      mutation: ForceRealEstateSelectionDocument,
      variables: {
        realEstateId: item.id,
        selectionType: SelectionType.ShellOnly
      },
      refetchQueries: [
        {
          query: ProjectRealEstatesDocument,
          variables: { projectId: this.projectId }
        }
      ],
      error: 'E4203'
    }).finally(() => {
      this.isLoading = false
    })
  }

  confirmSelectStandard(item: RealEstateItem) {
    this.isLoading = true
    this.$apolloMutate<ForceRealEstateSelectionMutation, ForceRealEstateSelectionMutationVariables>({
      mutation: ForceRealEstateSelectionDocument,
      variables: {
        realEstateId: item.id,
        selectionType: SelectionType.Standard
      },
      refetchQueries: [
        {
          query: ProjectRealEstatesDocument,
          variables: { projectId: this.projectId }
        }
      ],
      error: 'E4203'
    }).finally(() => {
      this.isLoading = false
    })
  }

  openExternalActionPopup(item: RealEstateItem) {
    this.selectedRealEstate = item.item
    this.showExternalActionPopup = true
  }

  closeExternalActionPopup() {
    this.selectedRealEstate = null
    this.showExternalActionPopup = false
  }

  openAddNotePopup(item: RealEstateItem) {
    this.selectedRealEstate = item.item
    this.showAddNotePopup = true
  }

  closeAddNotePopup() {
    this.selectedRealEstate = null
    this.showAddNotePopup = false
  }

  clickUploadDocuments(item: RealEstateItem, isSendDossier = false) {
    this.selectedRealEstate = item.item
    this.showUploadDocumentsDialog = true
    this.isSendDossierDialog = isSendDossier
  }

  closeUploadDocuments() {
    this.selectedRealEstate = null
    this.showUploadDocumentsDialog = false
  }
}
