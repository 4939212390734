/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type OrderOverviewActivitiesQueryVariables = Types.Exact<{
  lang: Types.Scalars['String'];
}>;


export type OrderOverviewActivitiesQuery = { projectActivities: Array<{ id: any, position: number, positionLabel: string, activityName: string, productCombination?: { id: any, position: number, combinationType: Types.ProductCombinationType } | null }> };

export type OrderOverviewActivityFragment = { id: any, position: number, positionLabel: string, activityName: string, productCombination?: { id: any, position: number, combinationType: Types.ProductCombinationType } | null };

export const OrderOverviewActivityFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"orderOverviewActivity"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectActivity"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"position"}},{"kind":"Field","name":{"kind":"Name","value":"positionLabel"}},{"kind":"Field","name":{"kind":"Name","value":"activityName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cultureCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"lang"}}}]},{"kind":"Field","name":{"kind":"Name","value":"productCombination"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"position"}},{"kind":"Field","name":{"kind":"Name","value":"combinationType"}}]}}]}}]} as unknown as DocumentNode<OrderOverviewActivityFragment, unknown>;
export const OrderOverviewActivitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OrderOverviewActivities"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"lang"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"projectActivities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"orderOverviewActivity"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"orderOverviewActivity"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectActivity"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"position"}},{"kind":"Field","name":{"kind":"Name","value":"positionLabel"}},{"kind":"Field","name":{"kind":"Name","value":"activityName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cultureCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"lang"}}}]},{"kind":"Field","name":{"kind":"Name","value":"productCombination"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"position"}},{"kind":"Field","name":{"kind":"Name","value":"combinationType"}}]}}]}}]} as unknown as DocumentNode<OrderOverviewActivitiesQuery, OrderOverviewActivitiesQueryVariables>;