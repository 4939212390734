
import { Component, Vue } from 'vue-property-decorator'
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import ProductPackageUploadImportDialog from '@/components/productPackageUploadImportDialog/ProductPackageUploadImportDialog.vue'
import { DataOptions, DataTableHeader } from 'vuetify'
import {
  ImportProductPackagesDocument,
  ImportProductPackagesQuery,
  ImportProductPackagesQueryVariables,
  ProductPackageFragment
} from '@/sharedQueries/gql/__generated__/productPackageImportsView.query'
import { ImportStatus } from '@/__generated__/globalTypes'

@Component({
  components: {
    ProductPackageUploadImportDialog
  }
})
export default class ProductPackageImportsView extends Vue {
  POLL_INTERVAL = 3000

  productPackageImports: ProductPackageFragment[] = []

  tableOptions: DataOptions = {} as DataOptions

  RouteNames = RouteNames
  showForm = false

  created() {
    this.tableOptions.sortBy = ['uploadDate']
    this.tableOptions.sortDesc = [true]

    this.$apollo.addSmartQuery<ImportProductPackagesQuery, ImportProductPackagesQueryVariables>(
      QUERIES.ImportProductPackages,
      {
        query: ImportProductPackagesDocument,
        update: (data) => data,
        variables: () => this.$route.params,
        result: (result) => {
          this.productPackageImports = (result?.data?.importProductPackages as ProductPackageFragment[]) ?? []

          const isAnyImportInProgress = this.productPackageImports.some((x) => this.isImportInProgress(x))
          if (isAnyImportInProgress) {
            this.$apollo.queries[QUERIES.ImportProductPackages].startPolling(this.POLL_INTERVAL)
          } else {
            this.$apollo.queries[QUERIES.ImportProductPackages].stopPolling()
          }
        },
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4176',
            Message: error
          })
        }
      }
    )
  }

  get headers() {
    return [
      { text: this.$t('productPackage.imports.list.headers.productPackageName'), value: 'productPackageName' },
      { text: this.$t('productPackage.imports.list.headers.fileName'), value: 'filename' },
      { text: this.$t('productPackage.imports.list.headers.uploadDate'), value: 'uploadDate' },
      { text: this.$t('productPackage.imports.list.headers.path'), value: 'path' },
      { text: this.$t('productPackage.imports.list.headers.status'), value: 'status' },
      { text: this.$t('productPackage.imports.list.headers.finishDate'), value: 'finishDate' }
    ] as DataTableHeader[]
  }

  get isReadonly() {
    return !this.$store.getters.isAdmin
  }

  get isLoading() {
    return this.$apollo.queries.ImportProductPackages?.loading
  }

  isImportInProgress(item: ProductPackageFragment) {
    return item.status === ImportStatus.New || item.status === ImportStatus.Processing
  }

  onFileUploaded() {
    this.showForm = false
    this.$apollo.queries.ImportProductPackages.refetch()
  }

  getProductPackageImportTemplate() {
    const filename = `${this.$t('productPackage.imports.getTemplateFilename')}`
    this.$fileApi
      .downloadFile(`/importproductpackage/template`, filename)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4195',
          Message: error
        })
      })
  }
}
