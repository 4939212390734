
import { Component, Vue } from 'vue-property-decorator'
import AddStyleDialog from '@/components/styles/AddStyleComponent.vue'
import { DELETE_STYLE_MUTATION } from './stylesViewMutations'
import { DeleteStyle, DeleteStyleVariables } from './__generated__/DeleteStyle'
import QUERIES from '@/queries/queries'
import { Badkamercore_ProjectType } from '@/__generated__/globalTypes'
import { RouteNames } from '@/router/routeNames'
import {
  DeleteProductPackageMutation,
  DeleteProductPackageMutationVariables,
  DeleteProductPackageDocument
} from '../productPackageListView/gql/__generated__/DeleteProductPackage.mutation'
import {
  StylesViewQueryDocument,
  StylesViewQueryQuery,
  StylesViewQueryQueryVariables
} from './gql/__generated__/StylesViewQuery'
import {
  GetStylePageByAliasQueryDocument,
  GetStylePageByAliasQueryQuery,
  GetStylePageByAliasQueryQueryVariables
} from './gql/__generated__/GetStylePageByAliasQuery'
import CopyStyleDialog from '@/components/projectStyles/CopyStyleDialog.vue'
import UpdateProductPackageDialog from '@/components/updateProductPackageDialog/UpdateProductPackageDialog.vue'
import { StyleFragmentWithUpdateStatus } from './types'

@Component({
  components: {
    AddStyleDialog,
    CopyStyleDialog,
    UpdateProductPackageDialog
  }
})
export default class StylesView extends Vue {
  headers = [
    {
      text: '',
      value: 'productPackage.isMain',
      sortable: true,
      align: 'center',
      cellClass: 'c-style-listing__icon-column'
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    { text: '', value: 'actions', sortable: false, align: 'end' }
  ]
  styles: StyleFragmentWithUpdateStatus[] | null = null
  showForm = false
  areImportsLoading = false

  routeNames = RouteNames
  Badkamercore_ProjectType = Badkamercore_ProjectType

  showUpdateProductPackageDialog = false
  styleProductPackageToUpdateId = ''
  styleToUpdateName = ''

  // Data for copying a style
  showStyleCopyModal = false
  styleToCopyId = ''
  styleToCopyName = ''

  created() {
    this.fetchData()
  }

  get isNotAdmin() {
    return !this.$store.getters.isAdmin
  }

  assignRowClasses(item: StyleFragmentWithUpdateStatus) {
    return { 'c-style-listing__row': item.isBeingUpdated }
  }

  openUpdateProductPackageDialog(item: StyleFragmentWithUpdateStatus) {
    this.showUpdateProductPackageDialog = true
    this.styleProductPackageToUpdateId = item.productPackageId
    this.styleToUpdateName = item.name
  }

  openStyleCopyModal(item: StyleFragmentWithUpdateStatus) {
    this.showStyleCopyModal = true
    this.styleToCopyId = item.id
    this.styleToCopyName = item.name + this.$t('style.copy.form.defaultFieldValue')
  }

  fetchData() {
    this.$apollo.addSmartQuery<StylesViewQueryQuery, StylesViewQueryQueryVariables>(QUERIES.StylesViewQuery, {
      query: StylesViewQueryDocument,
      update: (data) => data,
      result: (result) => {
        this.styles = result?.data?.styles.map((style) => ({
          ...style,
          isBeingUpdated: false
        }))
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4102',
          Message: error
        })
      }
    })
  }

  openForm() {
    this.showForm = true
  }

  closeForm() {
    this.showForm = false
  }

  deleteStyle(item: StyleFragmentWithUpdateStatus) {
    if (item.productPackageId) {
      this.$apolloMutate<DeleteProductPackageMutation, DeleteProductPackageMutationVariables>({
        mutation: DeleteProductPackageDocument,
        variables: {
          productPackageId: item.productPackageId
        },
        refetchQueries: [QUERIES.StylesViewQuery],
        error: 'E4103'
      })
    } else {
      this.$apolloMutate<DeleteStyle, DeleteStyleVariables>({
        mutation: DELETE_STYLE_MUTATION,
        variables: {
          styleId: item.id
        },
        refetchQueries: [QUERIES.StylesViewQuery],
        error: 'E4103'
      })
    }
  }

  navigateToCms(item: StyleFragmentWithUpdateStatus) {
    this.$apollo
      .query<GetStylePageByAliasQueryQuery, GetStylePageByAliasQueryQueryVariables>({
        query: GetStylePageByAliasQueryDocument,
        variables: {
          aliasId: item.id
        }
      })
      .catch((reason) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4104',
          Message: reason
        })
      })
      .then((response) => {
        if (response) {
          const host = process.env.VUE_APP_CMS_URL
          const contentItemId = response.data.stylePage?.[0]?.contentItemId
          if (contentItemId) {
            const url = `${host}/Admin/Contents/ContentItems/${contentItemId}/Edit?returnUrl=%2FAdmin%2FContents%2FContentItems`
            window.open(url, 'editStyleInCms')
          } else {
            this.$store.dispatch('showErrorDialog', {
              Code: 'E4105'
            })
          }
        }
      })
  }

  get styleNames() {
    return this.styles?.map((item) => item.name.replace(/[^a-zA-Z0-9]/g, ''))
  }

  confirmDeleteStyle(item: StyleFragmentWithUpdateStatus) {
    this.$showConfirmationDialog({
      awaitConfirm: true,
      Callback: async () => {
        await this.deleteStyle(item)
        this.$hideConfirmationDialog()
      },
      Code: 'C4037'
    })
  }
}
