
import { ToastState } from '@/types/toastTypes'
import { Component, Vue } from 'vue-property-decorator'
import Toast from './Toast.vue'

@Component({
  components: {
    Toast
  }
})
export default class ToastManager extends Vue {
  get visibleToasts() {
    return this.$store.state.visibleToasts as ToastState[]
  }

  getToastPaddingBottom(index: number) {
    if (index === 0) return 0

    return this.visibleToasts.slice(0, index).reduce((prev, current) => {
      let height = 0
      const ref = (this.$refs[current.id] as Toast[])?.[0]

      if (ref?.contentHeight) {
        height = ref.contentHeight + 10
      }

      return prev + (height ?? 0)
    }, 0)
  }

  onToastVisibilityChange(id: number, visible: boolean) {
    if (!visible) {
      this.$store.dispatch('hideToast', id)
    }
  }
}
