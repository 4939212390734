
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AssignedActivity } from './types'

@Component
export default class ActivityCostField extends Vue {
  @Prop() activity!: AssignedActivity
  @Prop() costType!: keyof AssignedActivity
  @Prop() disabled!: boolean

  rules = {
    money: (value: string) => {
      if (!value) return true

      // Can contain numbers, commas and dots
      const regex = /^(?=.*[1-9])\d{1,9}(?:[.,]\d{1,2})?$/
      return regex.test(value) || this.$t('activities.table.validations.money')
    }
  }
}
