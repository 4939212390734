/**
 * Enum representing CRUD actions.
 */
export enum CrudAction {
    /**
     * Create action.
     * Represents the creation of a new resource.
     */
    Create,

    /**
     * Read action.
     * Represents the retrieval of an existing resource.
     */
    Read,

    /**
     * Update action.
     * Represents the modification of an existing resource.
     */
    Update,

    /**
     * Delete action.
     * Represents the removal of an existing resource.
     */
    Delete,
}