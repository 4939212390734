
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Badkamercore_ProjectType, ImportStatus } from '@/__generated__/globalTypes'
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import ProductPackageUploadImportDialog from '@/components/productPackageUploadImportDialog/ProductPackageUploadImportDialog.vue'
import {
  ImportProductPackagesDocument,
  ImportProductPackagesQuery,
  ImportProductPackagesQueryVariables,
  ProductPackageFragment
} from '@/sharedQueries/gql/__generated__/productPackageImportsView.query'
import { PropositionProductPackageWithUpdateStatus } from './types'
import { CrudAction } from '@/Enums/CrudAction'

@Component({
  components: {
    ProductPackageUploadImportDialog
  }
})
export default class UpdateProductPackageDialog extends Vue {
  @Prop() value!: PropositionProductPackageWithUpdateStatus
  @Prop() propositionType!: Badkamercore_ProjectType
  @Prop() show!: boolean
  @Prop() productPackageToUpdateId!: string
  @Prop() productPackageToUpdateName!: string
  
  POLL_INTERVAL = 3000
  
  crudAction = CrudAction.Update
  hasWatchedValue = false
  productPackageImports: ProductPackageFragment[] | null = null

  // The product packages (value prop) and productPackageImports are fetched with separate queries but both are needed to check updates on the product packages.
  // Two watchers ensure checkProductPackageUpdates runs after both queries complete, with further triggers on refetch of imports.

  @Watch('value')
  onValueChange() {
    // The watcher should only trigger once when the product packages go from null to populated. This is to update statuses when this query completes last,
    // while avoiding infinite loops from a watcher that updates the value it observes.
    if (this.productPackageImports && !this.hasWatchedValue) {
      this.checkProductPackageUpdates()
    }
    this.hasWatchedValue = true
  }

  @Watch('productPackageImports')
  onProductPackageImportsChange() {
    if (this.value) {
      this.checkProductPackageUpdates()
    }
  }

  created() {
    this.$apollo.addSmartQuery<ImportProductPackagesQuery, ImportProductPackagesQueryVariables>(
      QUERIES.ImportProductPackages,
      {
        query: ImportProductPackagesDocument,
        update: (data) => data,
        variables: () => ({
          propositionType: this.propositionType
        }),
        result: (result) => {
          this.productPackageImports = result?.data?.importProductPackages
        },
        watchLoading: (isLoading) => {
          // The loading state doesn't change with polling, but this desirable here since in the parent it should only show the skeleton loader on page load
          // when it is checking what product packages should be disabled.
          this.$emit('are-imports-loading', isLoading)
        }
      }
    )
  }

  checkProductPackageUpdates() {
    const updatedProductPackageStatuses =
      this.value?.map((pp) => {
        const importsForCurrentProductPackage =
          this.productPackageImports?.filter((x) => x.productPackageName === pp.name) ?? []

        const isProductPackageBeingUpdated = importsForCurrentProductPackage.some(
          (x) => x.status === ImportStatus.New || x.status === ImportStatus.Processing
        )

        return {
          ...pp,
          isBeingUpdated: isProductPackageBeingUpdated
        }
      }) ?? null

    this.$emit('input', updatedProductPackageStatuses)

    const isAnyProductPackageBeingUpdated = updatedProductPackageStatuses?.some((pp) => pp.isBeingUpdated)
    if (isAnyProductPackageBeingUpdated) {
      this.$apollo.queries[QUERIES.ImportProductPackages].startPolling(this.POLL_INTERVAL)
    } else {
      this.$apollo.queries[QUERIES.ImportProductPackages].stopPolling()
    }
  }

  onImportFileUploaded() {
    this.$router.push({ name: RouteNames.PRODUCT_PACKAGE_IMPORTS, params: { propositionType: this.propositionType } })
  }
}
