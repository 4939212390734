/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorDialogModel } from '@/types/dialogTypes'
import { ToastOptions } from '@/types/toastTypes'
import { MutationOptions, OperationVariables } from 'apollo-client'
import { FetchResult } from 'apollo-link'
import Vue from 'vue'
import { ClientOptions } from 'vue-apollo/types/vue-apollo'

const GRAPH_QL_NOT_AUTHENTICATED_CODE = 'AUTH_NOT_AUTHENTICATED'

export type CustomApolloMutationOptions<R = any, TVariables = OperationVariables> = MutationOptions<R, TVariables> &
  ClientOptions & {
    error?: string | ((error: any) => ErrorDialogModel)
    successToastOptions?: ToastOptions
    throwOnError?: boolean
  }

export default Vue.extend({
  methods: {
    async $apolloMutate<R = any, TVariables = OperationVariables>(options: CustomApolloMutationOptions<R, TVariables>) {
      try {
        const result = await this.$apollo.mutate<R, TVariables>(options)

        let toastOptions = {
          text: this.$t('global.toasts.actionSuccessful').toString()
        } as ToastOptions

        if (options.successToastOptions) {
          toastOptions = { ...toastOptions, ...options.successToastOptions }
        }
        this.$store.dispatch('showToast', toastOptions)

        return result
      } catch (error: any) {
        // Check if user is authenticated
        const notAuthenticated = error?.graphQLErrors?.[0]?.extensions.code === GRAPH_QL_NOT_AUTHENTICATED_CODE
        // If the user is not authenticated reload the page if the error dialog should not be shown or after closing the error dialog
        const Callback = notAuthenticated ? () => window.location.reload() : undefined

        // Check if the backend provided a code. If so, use that as the error code for the translations.
        const errorCodeFromBackend =
          error?.graphQLErrors?.[0]?.extensions.code ?? error?.networkError?.result?.errors?.[0]?.extensions?.code
        const errorObj = getErrorObject(errorCodeFromBackend, error, options, Callback)

        if (errorObj) {
          this.$store.dispatch('showErrorDialog', errorObj)
        } else if (Callback) {
          Callback()
        }

        if (!options.error || options.throwOnError) throw error
        else return false
      }
    }
  }
})

function getErrorObject<R = any, TVariables = OperationVariables>(
  errorCodeFromBackend: string | undefined,
  error: any,
  errorOptions: CustomApolloMutationOptions<R, TVariables>,
  Callback: (() => void) | undefined
) {
  // If we have an error code from the backend, we use that code for the translation
  if (errorCodeFromBackend) {
    const errorInfo = error?.graphQLErrors?.[0]?.extensions ?? error?.networkError?.result?.errors?.[0]?.extensions
    const datas = Object.keys(errorInfo).filter(
      (k: string) => !['code', 'message', 'remote', 'schemaName', 'stackTrace'].includes(k)
    )
    const values = datas.map((k) => ({ key: k, value: errorInfo?.[k] })) ?? []
    return {
      Code: errorCodeFromBackend,
      Data: values,
      Message: Callback
    }
  }

  // Otherwise, if a callback function is specified, use that.
  // If that is also not the case, use the provided error code from the frontend or the errors from the request (not translated)
  return typeof errorOptions.error === 'function'
    ? errorOptions.error({ error, Callback })
    : {
        Code: errorOptions.error,
        Message:
          error?.graphQLErrors?.[0]?.extensions?.message ??
          error?.networkError?.result?.errors?.[0]?.extensions?.message ??
          error,
        Callback
      }
}

declare module 'vue/types/vue' {
  interface Vue {
    $apolloMutate<R = any, TVariables = OperationVariables>(
      options: CustomApolloMutationOptions<R, TVariables>
    ): Promise<false | FetchResult<R, Record<string, any>, Record<string, any>>>
  }
}
