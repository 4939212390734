import {
  DesignPackageWallDtoInput,
  ProductCombinationType,
  ProductSetInfoKeyDataType
} from '@/__generated__/globalTypes'
import { AssignedProductPackageCategoryFragment } from './gql/__generated__/getDefaultProductSets.query'
import { GetDesignPackageRestrictionKeysQuery } from './gql/__generated__/getDesignPackageProductSetRestrictionKeys.query'
import { AssignedPreselectedProductPackageCategoryFragment } from './gql/__generated__/getPreselectedProductSets.query'

export interface Category {
  catId: string
  catTitle: string
}

export interface FieldInformation {
  item1: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item2: any
  isOptional: boolean
  dataType: ProductSetInfoKeyDataType
}

export interface SelectedCombination {
  id: string
  isSelected: boolean
  combinationType: ProductCombinationType
  combinationInfo: GetDesignPackageRestrictionKeysQuery | null
  values: FieldInformation[]
  walls: DesignPackageWallDtoInput[]
  categoriesWithAssignedProductSets: CategoriesWithAssignedProductSetsAndProjectDefaults[]
}

export interface CategoriesWithAssignedProductSetsAndProjectDefaults
  extends AssignedProductPackageCategoryFragment,
    AssignedPreselectedProductPackageCategoryFragment {
  selectedDefaultProductSetsForCategory: CategoryDefaultProductSets
  selectedPreselectedProductSetsForCategory: CategoryPreselectedProductSets
}

export interface CategoryDefaultProductSets {
  productCategoryId: string
  productSetIds: string[]
  setAsProjectDefault: boolean
  wallId?: string
}

export interface CategoryPreselectedProductSets {
  productCategoryId: string
  productSetIds: string
  wallId?: string
}

export const GuidEmpty = '00000000-0000-0000-0000-000000000000'

export const ProductSetInfoKeyComponentMapping = {
  [ProductSetInfoKeyDataType.Bool]: 'BoolField',
  [ProductSetInfoKeyDataType.Enum]: 'EnumField',
  [ProductSetInfoKeyDataType.Number]: 'NumberField',
  [ProductSetInfoKeyDataType.Decimal]: 'DecimalField',
  [ProductSetInfoKeyDataType.Percentage]: 'PercentageField',
  [ProductSetInfoKeyDataType.Percentage_0_100]: 'PercentageField',
  [ProductSetInfoKeyDataType.LengthMeasurement]: 'LengthMeasurementField',
  [ProductSetInfoKeyDataType.AreaMeasurement]: 'AreaMeasurementField',
  [ProductSetInfoKeyDataType.MultiEnum]: 'MultiEnumField',
  [ProductSetInfoKeyDataType.Color]: '',
  [ProductSetInfoKeyDataType.Text]: ''
}

export const RequiredDataTypes = new Set([
  ProductSetInfoKeyDataType.Number,
  ProductSetInfoKeyDataType.Decimal,
  ProductSetInfoKeyDataType.Percentage,
  ProductSetInfoKeyDataType.Percentage_0_100,
  ProductSetInfoKeyDataType.LengthMeasurement,
  ProductSetInfoKeyDataType.AreaMeasurement,
  ProductSetInfoKeyDataType.Enum,
  ProductSetInfoKeyDataType.MultiEnum
])

export enum AlwaysDisabledCategories {
  ShowerPartitionWallTiles = '5fa99337-2d8a-4fee-97d1-33fac5173805',
  BathCasingTiles = '75665ebb-b756-4cb9-a7ee-74ca489cf5f0'
}
