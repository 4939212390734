/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfirmationDialogModel, ErrorDialogModel, NotificationDialogModel } from '@/types/dialogTypes'
import { ToastOptions } from '@/types/toastTypes'
import Vue from 'vue'

export default Vue.extend({
  methods: {
    $showConfirmationDialog(model: ConfirmationDialogModel) { 
      return this.$store.dispatch('showConfirmationDialog', model)
    },
    $hideConfirmationDialog() {
      return this.$store.dispatch('hideConfirmationDialog')
    },
    $showErrorDialog(model: ErrorDialogModel) { 
      return this.$store.dispatch('showErrorDialog', model)
    },
    $hideErrorDialog() {
      return this.$store.dispatch('hideErrorDialog')
    },
    $showNotificationDialog(model: NotificationDialogModel) { 
      return this.$store.dispatch('showNotificationDialog', model)
    },
    $hideNotificationDialog() {
      return this.$store.dispatch('hideConfirmationDialog')
    },
    $showToastText(text: string) {
      return this.$store.dispatch('showToastText', text)
    },
    $showToast(options: ToastOptions) {
      return this.$store.dispatch('showToast', options)
    },
    $hideToast(id: number) {
      return this.$store.dispatch('hideToast', id)
    }
  }
})

declare module 'vue/types/vue' {
  interface Vue {
    $showConfirmationDialog(model: ConfirmationDialogModel): Promise<any>,
    $hideConfirmationDialog() : Promise<any>,
    $showErrorDialog(model: ErrorDialogModel) : Promise<any>,
    $hideErrorDialog() : Promise<any>,
    $showNotificationDialog(model: NotificationDialogModel) : Promise<any>,
    $hideNotificationDialog() : Promise<any>,
    $showToastText(text: string) : Promise<any>,
    $showToast(options: ToastOptions) : Promise<any>,
    $hideToast(id: number) : Promise<any>,
  }
}
