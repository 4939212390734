import { render, staticRenderFns } from "./ProductSetCard.vue?vue&type=template&id=c2a2cd38&scoped=true"
import script from "./ProductSetCard.vue?vue&type=script&lang=ts"
export * from "./ProductSetCard.vue?vue&type=script&lang=ts"
import style0 from "./ProductSetCard.vue?vue&type=style&index=0&id=c2a2cd38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2a2cd38",
  null
  
)

export default component.exports